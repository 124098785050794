<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <GlobalProjects
          :calendarTitle="`Tareas Próximos dias`"
        ></GlobalProjects>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GlobalProjects from "./GlobalProjects.vue";

export default {
  name: "GlobalProject",
  components: {
    GlobalProjects
  }
};
</script>

<style></style>
